import React, { useContext, useState, useEffect } from 'react';
import { Modal, Link, Container,Button, Typography, Grid, Box, useMediaQuery, useTheme } from '@mui/material';
import chroma from 'chroma-js';
import CTAButton from '../atoms/CTAButton';
import { SessionContext } from '../contexts/SessionContext';
import { useNavigate, Link as RouterLink } from 'react-router-dom';



const KaisPlace = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:960px)');
    const photoHeight = isSmallScreen ? '160px' : isMediumScreen ? '200px' : '300px';
    const { sessionUser } = useContext(SessionContext);
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box>
            <Container maxWidth="sm" style={{  }}>
                <Grid container direction="column" justifyContent="right" alignItems="right" spacing={2}>
                    <Grid item style={{ marginLeft: '-16px', marginRight: '-16px' }}>
                        <Link component={RouterLink} to="/20231119_161337_2000p.webp" target="_blank" rel="noopener noreferrer">
                            <img src="/20231119_161337.webp" alt="Kai's Place" style={{ width: '100%', height: photoHeight, objectFit: 'cover', objectPosition: 'center' }} />
                        </Link>
                    </Grid>
                    <Grid item>
                        <Typography variant="h4" component="h2">
                            Kai's Place
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ul>
                            {sessionUser &&<li><Link component={RouterLink} to="/2023-06-22_get-in-instructions.webp" target="_blank" rel="noopener noreferrer">Get in intructions</Link></li>}
                            <li>Remove your shoes when entering, expect a warm and cosy atmosphere.</li>
                            <li>Feel free to bring food and drinks, for yourself or to share. There is a fully-equiped kitchen, although consider that long preparations might interfere with conversations. Tea and coffee are available, just help yourself!</li>
                            {sessionUser &&<li>WiFi SSID: The Pyramid</li>}
                            {sessionUser &&<li>WiFi password: climbclimbclimb</li>}
                        </ul>
                    </Grid>
                    <Grid item style={{ marginLeft: '-16px', marginRight: '-16px' }}>
                        <Link component={RouterLink} to={sessionUser?"https://maps.app.goo.gl/ijLMwRg7oUEoc8ju6":"https://maps.app.goo.gl/qC3vHjkSG5pV48cT8"} target="_blank" rel="noopener noreferrer">
                            <img src="/Screenshot from 2023-11-19 16-39-23.webp" alt="Kai's Place" style={{ width: '100%', height: 'auto' }} />
                        </Link>
                    </Grid>
                    <Grid item style={{ marginLeft: '-16px', marginRight: '-16px', marginBottom: '-32px' }}>
                        <Link component={RouterLink} to="/20231119_160553_2000p.webp" target="_blank" rel="noopener noreferrer">
                            <img src="/20231119_160553.webp" alt="Kai's Place" style={{ width: '100%', height: photoHeight, objectFit: 'cover', objectPosition: 'center' }} />
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
export default KaisPlace;
