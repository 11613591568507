import React, { useState } from 'react';
import { Button, TextField, Container, Grid, Alert } from '@mui/material';
import axios from 'axios';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import PromptLayout from '../molecules/PromptLayout'
import { useNavigate } from "react-router-dom";


const SendOtpView = () => {
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [alert, setAlert] = useState({ show: false, message: '', severity: '' });

    const handleSendOtp = async () => {
        try {
            const response = await axios.post('/api/send-otp', { phoneNumber });
            const { token } = response.data;
            // Store the OTP token for later use and redirect to /verify
            localStorage.setItem('otpToken', token);
            // setAlert({ show: true, message: 'OTP sent successfully', severity: 'success' });
            navigate('/verify');
        } catch (error) {
            console.error(error);
            setAlert({ show: true, message: 'The code could not be sent. Please double check your number.', severity: 'error' });
        }
    };

    return (
        <PromptLayout
            alert={alert}
            message={'Enter your phone number. A one-time verification code will be sent to this number.'}
            input={<PhoneInput
                placeholder="Enter your phone number"
                defaultCountry="FR"
                value={phoneNumber}
                onChange={(value) => {
                    setPhoneNumber(value);
                }}
            />}
            action={<Button variant="contained" onClick={handleSendOtp} style={{ textTransform: 'none' }}>
                Send Code
            </Button>}
        />
    );
}

export default SendOtpView;
