
import React, { useState } from 'react';
import { Tooltip } from '@mui/material';

const ShortTapTooltip = ({ children, title }) => {
        const [open, setOpen] = useState(false);
    
        // Function to open the tooltip on touch start
        const handleTouch = () => {
            setOpen(true);
    
            // Set a timeout to close the tooltip after a short duration
            setTimeout(() => {
                setOpen(false);
            }, 1000); // Adjust this value as needed for the duration of the tooltip display
        };
    
        const handleMouseEnter = () => {
                setOpen(true);
            };
    
            const handleMouseLeave = () => {
                setOpen(false);
            };

        // Enhance the child element with the touch event handlers
        const childWithHandlers = React.cloneElement(children, {
            onTouchStart: handleTouch,
            onTouchEnd: handleTouch,
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
        });
    
        return (
            <Tooltip
                title={title}
                open={open}
                onClose={() => setOpen(false)}
                disableInteractive
            >
                {childWithHandlers}
            </Tooltip>
        );
};

export default ShortTapTooltip;
