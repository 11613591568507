import React, { useContext, useState } from 'react';
import { Modal, Link, Container,Button, Typography, Grid, Box, useMediaQuery, useTheme } from '@mui/material';
import chroma from 'chroma-js';
import CTAButton from '../atoms/CTAButton';
import { SessionContext } from '../contexts/SessionContext';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';

const Hero = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:960px)');
    const { sessionUser } = useContext(SessionContext);

    // const bgColor = chroma.mix(theme.palette.background.default, theme.palette.primary.main, 0.0005).saturate(0.3).css()
    const bgColor = theme.palette.background.default;
    const lighterColor = chroma(bgColor).brighten(0).css();

    const typographyVariant = isSmallScreen ? 'h5' : 'h4';
    const textContainerStyle = isMediumScreen ? { padding: '0 20px' } : {};

    return (
        <Box
            sx={{
                // background: `linear-gradient(180deg, ${bgColor} 0%, ${lighterColor} 100%)`,
                background: `linear-gradient(180deg, ${bgColor} 0%, ${lighterColor} 100%)`,
                paddingTop: theme.spacing(8),    // Adding top margin to avoid overlap with AppBar
                paddingBottom: theme.spacing(4),    // Adding top margin to avoid overlap with AppBar
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={isMediumScreen ? 2 : 4} alignItems="center" justifyContent="center">
                    
                    {/* Text Content */}
                    <Grid item xs={12} md={6} style={textContainerStyle}>
                        <Typography variant={typographyVariant} gutterBottom>
                            Join a{' '}
                            <span style={{ color: theme.palette.primary.main }}>
                                growth-oriented community
                            </span>{' '}
                            committed to elevating each other.
                        </Typography>
                        <Typography variant="subtitle1" paragraph>
                            We meet every other Saturday with a shared goal of overcoming our problems, acquiring new insights and wisdom, and getting to know each other.
                        </Typography>
                        {!sessionUser && <CTAButton/>}
                    </Grid>
                    
                    {/* Image */}
                    <Grid item xs={12} md={6}>
                        <img src={'/5ad053f4-b62e-414b-a194-e5ca90b1bd81_.webp'} alt="Hero" style={{ width: '100%', height: 'auto' }} />
                    </Grid>
                    
                </Grid>
            </Container>
        </Box>
    );
};

const MainContent = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const typographyVariant = isSmallScreen ? 'h4' : 'h3';
    const { sessionUser } = useContext(SessionContext);
    
    const bgColor = theme.palette.background.default;
    const bgColorLighter = chroma.mix(bgColor, theme.palette.primary.main, 0.008).saturate(0.2).css()
    // const bgColor = theme.palette.background.default;
    return (<>
        <Box
            sx={{
                // background: `linear-gradient(180deg, ${bgColor} 0%, ${lighterColor} 100%)`,
                background: `linear-gradient(180deg, ${bgColorLighter} 0%, ${bgColorLighter} 100%)`,
                paddingTop: theme.spacing(8),    // Adding top margin to avoid overlap with AppBar
                paddingBottom: theme.spacing(4),    // Adding top margin to avoid overlap with AppBar
            }}
        >
            <Container maxWidth="sm">
                    <Box display="flex" flexDirection="column" alignItems="right">
                            <Typography variant={typographyVariant} gutterBottom>
                                Who we are
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Our community consists of individuals at various stages in their personal and professional lives. It includes entrepreneurs, ambitious professionals, as well as individuals committed to continuous learning. Some members have already reached milestones like establishing thriving businesses, while others are at the early stages of their journey.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            What unites us is not where we are, but where we aim to go. The cornerstone of our community is a shared growth mindset, open-mindedness and acceptance of others.
                        </Typography>
                    </Box>
            </Container>
        </Box>
        <Box
            sx={{
                // background: `linear-gradient(180deg, ${bgColor} 0%, ${lighterColor} 100%)`,
                background: `linear-gradient(180deg, ${bgColor} 0%, ${bgColor} 100%)`,
                paddingTop: theme.spacing(8),    // Adding top margin to avoid overlap with AppBar
                paddingBottom: theme.spacing(4),    // Adding top margin to avoid overlap with AppBar
            }}
        >
            <Container maxWidth="sm">
                    <Box display="flex" flexDirection="column" alignItems="right">
                        <Typography variant={typographyVariant} gutterBottom>
                            What we do
                        </Typography>
                        <Typography variant="body1" paragraph>
                            The main gathering happens every other Saturday, noon to midnight, at <Link component={RouterLink} to="/kai-s-place" style={{ cursor: 'pointer' }}>
        Kai's place
    </Link>. Members can come at any time to catch up and share. Various group events are organized by members, typically between 15:00 and 17:00. They include <Link component={RouterLink} to="#socratesCafe">Socrates cafes</Link>, <Link component={RouterLink} to="#bigTalk">Big Talks</Link> and <Link component={RouterLink} to="#debugSession">Debug sessions</Link>, among many others.
                        </Typography>
                        <Typography variant="body1" paragraph>
                        Our shared priority is that you come out of it with new insights, renewed motivation, goals to achieve by next time, podcasts to listen, books to read, a sense of belonging, and peers who feel increasingly like close friends.
                        </Typography>
                        {!sessionUser && <CTAButton/>}
                    </Box>
            </Container>
        </Box>
    </>);
}


const LandingPage = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery('(max-width:600px)');


    return (<>
        <Hero />
        <MainContent />
    </>)};

export default LandingPage;
