import React, { useContext } from 'react';
import { Container, IconButton, Button, Menu, Typography, AppBar, Toolbar, Box, useMediaQuery, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';    // Import the MenuIcon
import CTAButton from '../atoms/CTAButton';
import { SessionContext } from '../contexts/SessionContext';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import UserBox from '../atoms/UserBox';

const Header = () => {
    const navigate = useNavigate();
    const isNarrowScreen = useMediaQuery('(max-width:420px)'); // Set your own breakpoint
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { sessionUser, logout } = useContext(SessionContext);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuItems = [];
    if(sessionUser) {
        menuItems.push({
            child: 'Dashboard',
            href: '/',
        });
        menuItems.push({
            child: 'Landing page',
            href: '/landing',
        });
        menuItems.push({
            child: 'Log out',
            onClick: ()=> {
                logout();
                handleMenuClose();
                navigate('/');
            },
        });

    }
    else {
        menuItems.push({
            child: <CTAButton onClick={handleMenuClose} />,
            excludeMenuItem: true,
        });
    }

    return (
        <AppBar position="static">
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <RouterLink to="/" style={{ color: 'inherit', textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                        <img 
                            src="/logo_only_white.svg" 
                            alt="Logo" 
                            style={{ 
                                width: '50px', 
                                marginRight: '15px' 
                            }} 
                        />
                        <Typography variant="h6" style={{ flexGrow: 1 }}>
                            Maslow Climbers
                        </Typography>
                    </RouterLink>
                    {isNarrowScreen || sessionUser ? (
                        <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                            {sessionUser ? (
                                <Button color="inherit" onClick={handleMenuClick} style={{ textDecoration: 'none', textTransform: 'none' }}>
                                    <UserBox user={sessionUser} size={isNarrowScreen? 40:45} />
                                </Button>
                                // <Button color="inherit" onClick={handleMenuClick} style={{ textDecoration: 'none', textTransform: 'none' }}>
                                //     {sessionUser.firstName || '…'+sessionUser.phoneNumber.slice(-4)}
                                // </Button>
                            ) : (
                                <IconButton color="inherit" aria-label="menu" onClick={handleMenuClick}>
                                    <MenuIcon />
                                </IconButton>
                            )}
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}>
                            {menuItems.map(({ child, href, onClick, excludeMenuItem }, i) => (
                                excludeMenuItem ? (
                                    <div key={i}>{child}</div>
                                ) : (
                                    onClick ? (
                                        <MenuItem key={i} onClick={onClick}>{child}</MenuItem>
                                    ) : (
                                        <MenuItem key={i} component={RouterLink} to={href} onClick={() => {handleMenuClose();}}>{child}</MenuItem>
                                    )
                                )
                            ))}
                            </Menu>
                        </Box>
                    ) : (
                        <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                            {!sessionUser ? <CTAButton /> : sessionUser.displayName}
                        </Box>
                    )} 
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
