import React, { } from 'react';
import {  Grid, Typography, Slider, useMediaQuery, Skeleton, Box } from '@mui/material';
import UserBox from '../../atoms/UserBox';
import daytimeToString from '../../helpers/daytimeToString';

const UserTimeRange = ({user,maxRange,range,onNewRange,keyColored=false,skeleton=false})=> {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const midPoint = skeleton ? 12 : (range[0] + range[1]) / 2;
    const midPointRatio = skeleton ? 0.5 : (midPoint-maxRange[0])/(maxRange[1]-maxRange[0]);

    return (<Box display="flex" flexDirection="row" alignItems="center" gap={{ xs: 1.5, sm: 3 }}>
        <Box><UserBox user={user} skeleton={!user?.displayName} /></Box>
        <Box sx={{
                flexGrow: 1,
                paddingTop: '5px', // cheat to center the slider visually
            }}
            position="relative"
            // bgcolor="grey.500"
        >
            <Box
                position="absolute"
                top={skeleton ? -20 : -10}
                left={midPointRatio<0.5?(midPointRatio*100)+'%':undefined}
                right={midPointRatio<0.5?undefined:((1-midPointRatio)*100)+'%'}
                sx={{ transform: `translateX(${midPointRatio<0.5?'-':'+'}50%)` }}
            >
                    <Typography variant="subtitle2" style={{ color: 'gray' }}>{
                        skeleton
                        ? <Skeleton width={90} />
                        : `${daytimeToString(range[0])} - ${daytimeToString(range[1])}`
                    }</Typography>
            </Box>
            {skeleton
                ? <Skeleton height={9} />
                : <Slider
                value={range}
                onChange={onNewRange ? (event, newValue) => onNewRange(newValue) : undefined}
                step={0.5}
                marks
                min={maxRange[0]}
                max={maxRange[1]}
                // disabled={!onNewRange}
                disabled={!keyColored}
                // valueLabelDisplay="auto"
                getAriaValueText={daytimeToString}
                valueLabelFormat={daytimeToString}
                disableSwap={range[0] != range[1]} // not disabled initially when both on same position
                // color={userKeyColor}
                sx={{
                    '& .MuiSlider-thumb': {
                        // bgcolor: thumbColor,
                        // backgroundColor: onNewRange ? '#fff' : thumbColor,
                        // border: '2px solid '+thumbColor,
                        backgroundColor: onNewRange ? '#fff' : undefined,
                        border: '2px solid currentColor',
                        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                            boxShadow: 'inherit',
                        },
                        '&:before': {
                            display: 'none',
                        },
                        width: onNewRange ? '18px' : '8px',
                        height: onNewRange ? '18px' : '8px',
                    },
                    // '& .MuiSlider-track': {
                    //     bgcolor: trackColor,
                    //     borderColor: trackColor,
                    // },
                    // '& .MuiSlider-rail': {
                    //     bgcolor: railColor,
                    // },
                    // '& .MuiSlider-mark': {
                    //     bgcolor: thumbColor,
                    // },
                }}
            />}
        </Box>
    </Box>);
    return (
        <Grid item container spacing={isSmallScreen ? 1.5 : 3} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Grid item>
                <UserBox user={user} skeleton={!user?.displayName} />
            </Grid>
            <Grid item xs style={{ flex: 1 }}>
                <Box position="relative" display="flex" alignItems="center" justifyContent="center">
                    <Box
                        position="absolute"
                        top={skeleton ? -20 : -10}
                        left={midPointRatio<0.5?(midPointRatio*100)+'%':undefined}
                        right={midPointRatio<0.5?undefined:((1-midPointRatio)*100)+'%'}
                        sx={{ transform: `translateX(${midPointRatio<0.5?'-':'+'}50%)` }}
                    >
                            <Typography variant="subtitle2" style={{ color: 'gray' }}>{
                                skeleton
                                ? <Skeleton width={90} />
                                : `${daytimeToString(range[0])} - ${daytimeToString(range[1])}`
                            }</Typography>
                    </Box>
                </Box>
                {skeleton
                    ? <Skeleton height={9} />
                    : <Slider
                    value={range}
                    onChange={onNewRange ? (event, newValue) => onNewRange(newValue) : undefined}
                    step={0.5}
                    marks
                    min={maxRange[0]}
                    max={maxRange[1]}
                    // disabled={!onNewRange}
                    disabled={!keyColored}
                    // valueLabelDisplay="auto"
                    getAriaValueText={daytimeToString}
                    valueLabelFormat={daytimeToString}
                    disableSwap
                    // color={userKeyColor}
                    sx={{
                        '& .MuiSlider-thumb': {
                            // bgcolor: thumbColor,
                            // backgroundColor: onNewRange ? '#fff' : thumbColor,
                            // border: '2px solid '+thumbColor,
                            backgroundColor: onNewRange ? '#fff' : undefined,
                            border: '2px solid currentColor',
                            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                              boxShadow: 'inherit',
                            },
                            '&:before': {
                              display: 'none',
                            },
                            width: onNewRange ? '18px' : '8px',
                            height: onNewRange ? '18px' : '8px',
                        },
                        // '& .MuiSlider-track': {
                        //     bgcolor: trackColor,
                        //     borderColor: trackColor,
                        // },
                        // '& .MuiSlider-rail': {
                        //     bgcolor: railColor,
                        // },
                        // '& .MuiSlider-mark': {
                        //     bgcolor: thumbColor,
                        // },
                    }}
                />}
            </Grid>
        </Grid>);
}

export default UserTimeRange;
