import React, { useContext, useEffect } from 'react';
import { SessionContext } from '../contexts/SessionContext';
import { useNavigate } from "react-router-dom";

function useAutoRedirect() {
    const navigate = useNavigate();
    const { sessionUser } = useContext(SessionContext);
    const redirectTo = ()=> {
        const currentRoute = window.location.pathname;
        const redirectIfNeeded = target=> target !== currentRoute ? target : undefined;
        if(!sessionUser) return redirectIfNeeded('/login')
        if(!sessionUser.invitation?.userId) return redirectIfNeeded('/request-invitation')
        if(!sessionUser.firstName || !sessionUser.lastName) return redirectIfNeeded('/whats-your-name')
    }
    useEffect(()=>{
        const target = redirectTo();
        if(target) navigate(target);
    },[]);
    return !!redirectTo();
}
export default useAutoRedirect;
