import React, { useContext, useEffect } from 'react';
import { Container, Typography, Grid, Box, Link, Button } from '@mui/material';
import chroma from 'chroma-js';
import { SessionContext } from '../contexts/SessionContext';
import { useNavigate } from "react-router-dom";
import useAutoRedirect from '../hooks/useAutoRedirect';

const RequestInvitation = () => {
    const navigate = useNavigate();
    const { sessionUser, logout } = useContext(SessionContext);
    if(useAutoRedirect()) return <></>;
    return (
    <Container maxWidth="sm" style={{ paddingTop: '2em', paddingBottom: '6em' }}>
        <Grid container spacing={4}>
        <Grid item sm={12} xs={12}>
            It seems you were not yet sent an invitation by a verified member. Please ask for one to continue. If you don't know any member yet, you can <Link 
        href={`mailto:${encodeURIComponent('Kai Elvin<contact@kaielvin.org>')}?subject=${encodeURIComponent('Invitation Request to join Maslow Climbers')}&body=${encodeURIComponent(`\n\nMy phone number: ${sessionUser.phoneNumber}`)}`}
        target="_blank" 
        rel="noopener noreferrer"
    >
        email Kai
    </Link>. Invitations are sent by SMS, so do provide your phone number.
        </Grid>
            <Grid item sm={12} xs={12}>
                <Button onClick={()=> {logout(); navigate('/')}}>
                    Log out
                </Button>
            </Grid>
        </Grid>
    </Container>);
}

export default RequestInvitation;
