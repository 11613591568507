import React, { useState, useEffect } from 'react';
import { Container, Grid, CircularProgress } from '@mui/material';

import axios from 'axios';

export const SessionContext = React.createContext();

export const SessionProvider = ({ children }) => {
    const [sessionToken, setSessionTokenState] = useState(() => {
        // Restore the session token when the app loads
        return localStorage.getItem('sessionToken') || '';
    });
    const [sessionUser, setSessionUser] = useState(() => {
        // Restore the session token when the app loads
        // const userStr = localStorage.getItem('sessionUser');
        // // console.log('userStr',userStr);
        // try {
        //     // console.log("JSON.parse('userStr')",JSON.parse(userStr));
        //     if(userStr) return JSON.parse(userStr);
        // }
        // catch(e) {
        //     // console.error('parse error',e);
        // }
        // return undefined;
    });

    const setSessionToken = token=> {
        if(token === '') localStorage.removeItem('sessionToken');
        else localStorage.setItem('sessionToken', token);
        setSessionTokenState(token);
    }

    // useEffect(() => {
    //     // Persist the session when it changes
    //     localStorage.setItem('sessionToken', sessionToken);
    // }, [sessionToken]);
    // useEffect(() => {
    //     // Persist the session when it changes
    //     localStorage.setItem('sessionUser', JSON.stringify(sessionUser));
    // }, [sessionUser]);

    useEffect(() => {
        (async ()=> {
            const sessionToken = localStorage.getItem('sessionToken');
            if(sessionToken) try {
                const response = await axios.post('/api/check-session', {}, {
                    headers: {
                        'x-access-token': sessionToken
                    }
                });
                const { user } = response.data;
                // Store the session token for future calls
                setSessionUser(user);
                // navigate('/');
            } catch (error) {
                console.error(error);
            }
        })()
    }, []);


    const logout = ()=> {
        setSessionToken('');
        setSessionUser(undefined);
    }

    // console.log('sessionToken',sessionToken);
    // console.log('sessionUser',sessionUser);

    return (
        <SessionContext.Provider value={{ sessionToken, setSessionToken, sessionUser, setSessionUser, logout }}>
            {sessionToken && !sessionUser ? (
                <Container maxWidth="sm" style={{ paddingTop: '2em', paddingBottom: '6em' }}>
                    <Grid container direction="column" justifyContent="right" alignItems="right" spacing={2}>
                            <Grid item><CircularProgress /></Grid>
                    </Grid>
                </Container>)
                : children}
        </SessionContext.Provider>
    );
};