import React, { } from 'react';
import { Avatar, Skeleton, Box } from '@mui/material';
import ShortTapTooltip from './generic/ShortTapTooltip';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';

function stringToColor(str) {
        let hash = 5;
        let i;
    
        /* eslint-disable no-bitwise */
        for (i = 0; i < str.length; i += 1) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
    
        let color = '#';
    
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
    
        return color;
    }
    


const UserBox = ({user, imageSize=45, skeleton=false})=> {
    const theme = useTheme();
    const backgroundColorAlpha = chroma(theme.palette.background.default).alpha(0.6).css();
    const userKeyColor = skeleton || !user?.displayName ? '#999' : stringToColor(user?.displayName);

    return (<ShortTapTooltip title={user?.displayName} disableInteractive>
        {skeleton ? (<Skeleton variant="circular">
                <Avatar 
                    sx={{width: imageSize, height: imageSize}}
            />
        </Skeleton>) : (<Box sx={{position:'relative', borderRadius: '50%', overflow: 'hidden'}}>
                <Avatar
                    sx={{bgcolor: userKeyColor, width: imageSize, height: imageSize}}
                    alt={user.displayName}
                    src={user.photoId ? `https://maslow-climbers.club/images/${user.photoId}.webp` : undefined}>{
                            user.photoId ? undefined
                            : user.displayName ? `${user.displayName.split(' ')[0][0]}${user.displayName.split(' ')[1][0]}`
                            : undefined
                }</Avatar>
                {user.status !== 'established' && <Box sx={{
                    position:'absolute',
                    bottom:0,
                    right:0,
                    left:0,
                    paddingTop: '1em',
                    fontSize: '0.4em',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'flex-end',
                    background: `linear-gradient(transparent, ${backgroundColorAlpha}, ${backgroundColorAlpha})`,
                    paddingBottom: '2px',
                }}>
                    Invited
                </Box>}
            </Box>)
            }
    </ShortTapTooltip>);
}

export default UserBox;