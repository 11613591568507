import React from 'react';
import { Container, Grid, Alert, useMediaQuery  } from '@mui/material';
import 'react-phone-number-input/style.css'


const PromptLayout = (props) => {
    const { alert, message, input, action, footer } = props;
    const hasMultipleInputs = Array.isArray(input) && input.length > 1;
    const isNarrowScreen = useMediaQuery('(max-width:600px)');
    return (
        <Container maxWidth="sm" style={{ paddingTop: '2em', paddingBottom: '6em' }}>
            <Grid container direction={isNarrowScreen ? "column" : "row"} justifyContent="center" alignItems="center" spacing={2}>
            {alert.show && <Grid item><Alert severity={alert.severity}>{alert.message}</Alert></Grid>}
                {!!message && <Grid item sm={12} xs={12}>
                    {message}
                </Grid>}
                {!!input && !hasMultipleInputs && <Grid item sm={6}>
                    {input}
                </Grid>
                || (
                    input.map((subinput,i)=>(
                        <Grid key={i} item sm={4}>
                            {subinput}
                        </Grid>
                    ))
                )}
                {!!action && <Grid item sm={hasMultipleInputs ? 4 : 6}>
                    {action}
                </Grid>}
                {!!footer && <Grid item sm={12}>
                    {footer}
                </Grid>}
            </Grid>
        </Container>
    );
}

export default PromptLayout;
