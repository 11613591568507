import { createContext, useContext } from 'react';
import { SessionContext } from './SessionContext';
import useFetchUsers from '../hooks/useFetchUsers';

export const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
    const { sessionToken } = useContext(SessionContext);
    const { users } = useFetchUsers(sessionToken);

    return (
        <UsersContext.Provider value={{ users }}>
            {children}
        </UsersContext.Provider>
    );
};

 