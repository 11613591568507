import React from 'react';
import { Button, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const CTAButton = ({onClick}) => {
    return (
        <Link to="/login" component={RouterLink} style={{ textDecoration: 'none' }} onClick={onClick}>
            <Button variant="contained" color="primary" style={{ textTransform: 'none' }}>
                Let me in!
            </Button>
        </Link>
    );
}

export default CTAButton;
