import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchUsers = (sessionToken) => {
    const [users, setUsers] = useState(undefined);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                // await new Promise(resolve => setTimeout(resolve, 2500));
                const response = await axios.get('/api/users', {
                    headers: {
                        'x-access-token': sessionToken
                    }
                });
                const { users } = response.data;
                setUsers(users);
            } catch (error) {
                console.error(error);
            }
        };
    
        if(sessionToken) fetchUsers();
    }, [sessionToken]);

    return { users };
};

export default useFetchUsers;