import React from 'react';
import Header from './Header';
import Footer from './Footer';
import {    CssBaseline, ThemeProvider, createTheme } from '@mui/material';


// Create a dark theme
const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const Layout = ({ children }) => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <div>
        <Header />
        {children}
        <Footer />
        </div>
    </ThemeProvider>
);

export default Layout;
