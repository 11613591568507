import React from 'react';
import { Container, Typography } from '@mui/material';

const Footer = () => (
        <Container>
            <Typography variant="body2" color="text.secondary" align="center" style={{ marginTop: '2em' }}>
                {/* © 2023 Dark Theme Landing Page */}
            </Typography>
        </Container>
    );

export default Footer;
