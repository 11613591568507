
const daytimeToString = value => `${
        String(Math.floor(value)%24).padStart(2,'0')
    }:${
        value % 1 === 0.25 ? '15'
        : value % 1 === 0.5 ? '30'
        : value % 1 === 0.75 ? '45'
        : '00'
    }`

export default daytimeToString;
