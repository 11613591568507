import React, { useState, useContext } from 'react';
import { Button, TextField, Typography, Grid, Alert, useMediaQuery } from '@mui/material';
import axios from 'axios';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useNavigate } from "react-router-dom";
import { SessionContext } from '../../contexts/SessionContext';


const SendInvite = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [alert, setAlert] = useState({ show: false, message: '', severity: '' });
    const isNarrowScreen = useMediaQuery('(max-width:420px)'); // Set your own breakpoint
    const { sessionToken, sessionUser } = useContext(SessionContext);

    const handleSend = async () => {
        try {
            const response = await axios.post('/api/send-invite', { phoneNumber, firstName, lastName },
                    {headers: {'x-access-token': sessionToken}});
            const { code } = response.data;
            const formatDate = (dateString) => {
                const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
                return new Date(dateString).toLocaleDateString(undefined, options);
            }
            if(code === 'already-invited')
                setAlert({ show: true, message: response.data.invitation?.userId === sessionUser._id
                        ? `You have already sent an invitation to this person. They can head to maslow-climbers.club and log in.` :
                        `This person has already been invited by ${response.data.invitation?.userDisplayName} on ${formatDate(response.data.invitation?.date)}.`,
                        severity: 'info' });
            else if(code === 'success') {
                setAlert({ show: true, message: `Invitation send. Thanks for your contribution!`, severity: 'success' });
            }
            setFirstName('');
            setLastName('');
            setPhoneNumber('');
        } catch (error) {
            console.error(error);
            setAlert({ show: true, message: 'The invitation could not be sent. Please double check the phone number.', severity: 'error' });
        }
    };

    return (<Grid container direction={isNarrowScreen ? "column" : "row"} spacing={2}>
        {alert.show && <Grid item><Alert severity={alert.severity}>{alert.message}</Alert></Grid>}
            <Grid item xs={6} sm={5}>
                <TextField
                    label="First name"
                    value={firstName}
                    sx={{width:'100%'}}
                    onChange={(e) => setFirstName(e.target.value)}
                    autoComplete="given-name" // Add this line to enable browser autofill for first name
                />
            </Grid>
            <Grid item xs={6} sm={5}>
                <TextField
                    label="Last name"
                    value={lastName}
                    sx={{width:'100%'}}
                    onChange={(e) => setLastName(e.target.value)}
                    autoComplete="family-name" // Add this line to enable browser autofill for last name
                />
            </Grid>
            <Grid item xs={12} sm={10}>
            <PhoneInput
                placeholder="Invitee's phone number"
                defaultCountry="FR"
                value={phoneNumber}
                onChange={(value) => {
                    setPhoneNumber(value);
                }}
            />
            </Grid>
            <Grid item xs={12} sm={10}>
            They will receive an SMS confirming your invitation. They can then head to maslow-climbers.club and log in using this same phone number.
            </Grid>
            <Grid item xs={12} sm={8}>
            <Button variant="contained" onClick={handleSend} style={{ textTransform: 'none' }}>
                Send the invite
            </Button>
            </Grid>
        </Grid>
    );
}

export default SendInvite;
