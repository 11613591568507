import React, { useState, useContext } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { SessionContext } from '../contexts/SessionContext';
import PromptLayout from '../molecules/PromptLayout';
import { useNavigate } from "react-router-dom";
import useAutoRedirect from '../hooks/useAutoRedirect';

const SetDisplayName = () => {
    const { sessionToken, sessionUser, setSessionUser } = useContext(SessionContext);
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState(sessionUser?.firstName || '');
    const [lastName, setLastName] = useState(sessionUser?.lastName || '');
    const [alert, setAlert] = useState({ show: false, message: '', severity: '' });
    if(useAutoRedirect()) return <></>;
    const onSubmit = async () => {
        try {
            const response = await axios.post('/api/set-user-field', { firstName, lastName }, {
                headers: {
                    'x-access-token': sessionToken
                }
            });
            const { user } = response.data;
            // Store the session token for future calls
            setSessionUser(user);
            navigate('/');
        } catch (error) {
            console.error(error);
            setAlert({ show: true, message: 'Something went wrong. Make sure both fields are filled.', severity: 'error' });
        }
    };
    return (
        <PromptLayout
            alert={alert}
            message={'What name do you go by?'}
            input={[
                <TextField
                    label="First name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    autoComplete="given-name" // Add this line to enable browser autofill for first name
                />,
                <TextField
                    label="Last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    autoComplete="family-name" // Add this line to enable browser autofill for last name
                />
            ]}
            action={<Button variant="contained" onClick={onSubmit}>
                    Next
                </Button>}
            footer={<Typography variant="body2" color="textSecondary">Your personal information is only accessible to verified members.</Typography>}
        />
    );
}

export default SetDisplayName;
