import React, { useState, useContext } from 'react';
import { Button, TextField, Container, Grid, Alert } from '@mui/material';
import axios from 'axios';
import { SessionContext } from '../contexts/SessionContext';
import PromptLayout from '../molecules/PromptLayout';
import { useNavigate } from "react-router-dom";

const VerifyOtpView = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const { sessionToken, setSessionToken, sessionUser, setSessionUser } = useContext(SessionContext);
    const [alert, setAlert] = useState({ show: false, message: '', severity: '' });

    const onSubmit = async () => {
        const otpToken = localStorage.getItem('otpToken');
        try {
            const response = await axios.post('/api/verify-otp', { token: otpToken, otp });
            const { token, user } = response.data;
            console.log({token, user});
            // Store the session token for future calls
            setSessionToken(token);
            setSessionUser(user);
            localStorage.removeItem('otpToken');
            // setAlert({ show: true, message: 'OTP verified successfully', severity: 'success' });
            navigate('/');
        } catch (error) {
            console.error(error);
            setAlert({ show: true, message: 'Oops, looks like the code is invalid, please try again.', severity: 'error' });
        }
    };

    return (
        <PromptLayout
            alert={alert}
            message={'Check your phone and enter the 6-digit code that was sent to you.'}
            input={<TextField
                label="Verification Code"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
            />}
            action={<Button variant="contained" onClick={onSubmit} style={{ textTransform: 'none' }}>
                    Verify
                </Button>}
        />
    );
}

export default VerifyOtpView;
