import React, { useContext, useEffect, useState } from 'react';
import { Container, Grid, Stack, Paper, Alert, Typography, Avatar, Button, Slider, useMediaQuery, Tooltip, Link, Skeleton } from '@mui/material';
import { SessionContext } from '../contexts/SessionContext';
import { UsersContext } from '../contexts/UsersContext';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import useAutoRedirect from '../hooks/useAutoRedirect';
import UserTimeRange from '../molecules/events/UserTimeRange';
import EventLine from '../atoms/EventLine';
import UserBox from '../atoms/UserBox';
import daytimeToString from '../helpers/daytimeToString';
import SendInvite from './subviews/SendInvite';
import Box from '@mui/material/Box';
import axios from 'axios';
import { Collapse, IconButton } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { canEditRsvp, isFutureEvent } from '../helpers/events';



const Dashboard = () => {
    const theme = useTheme();
    const backgroundColor = theme.palette.background.default;
    // const [users, setUsers] = useState(undefined);
    const [events, setEvents] = useState(undefined); // Added events state
    const [alert, setAlert] = useState({ show: false, message: '', severity: '' });
    const { sessionToken, sessionUser } = useContext(SessionContext);
    const { users } = useContext(UsersContext);
    const [pastEventsExpanded, setPastEventsExpanded] = useState(true);
    const [timeRange, setTimeRange] = useState([15, 19]); // Add this state

    useEffect(() => {
        const fetchEvents = async () => {
            // await new Promise(resolve => setTimeout(resolve, 2500));
            try {
                const response = await axios.get('/api/events', { // Fetch events from the server
                    headers: {
                        'x-access-token': sessionToken
                    }
                });
                const { events } = response.data;
                // console.log(events.filter(event => !isFutureEvent(event)));
                setPastEventsExpanded(events.filter(event => !isFutureEvent(event)).length < 8);
                setEvents(events.filter(event => !event.cancelledAt));
            } catch (error) {
                console.error(error);
                setAlert({ show: true, message: 'Something went wrong. Try refreshing.', severity: 'error' });
            }
        };
    
        fetchEvents();
    }, []);

    const futureEvents = !events ? Array.from({ length: 3 }, (_, i) => ({ _id: String(i) })) : events.filter(isFutureEvent);
    futureEvents.sort((a, b) => new Date(a.startAt) - new Date(b.startAt));
    const pastEvents = !events ? Array.from({ length: 8 }, (_, i) => ({ _id: String(i) })) : events && events.filter(event => !isFutureEvent(event));
    pastEvents.sort((a, b) => new Date(b.startAt) - new Date(a.startAt));

    const eventCard = event => (
        <Link key={event._id} component={RouterLink} to={`/event/${event._id}`} underline="none" sx={{maxWidth: '17em', width: '100%'}}>
            <Paper sx={{paddingX: '1em', paddingY: '0.5em'}}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="subtitle1" align="left">{event?.title || <Skeleton width="7em" />}</Typography>
                        <EventLine event={event} />
                    </Grid>
                    {canEditRsvp(event) && (
                        <Grid item>
                            <Button
                                sx={{ 
                                    textTransform: 'none'
                                }}
                                align="right">Attend</Button>
                        </Grid>
                    )}
                </Grid>
            </Paper> 
        </Link>
    )
    
    if(useAutoRedirect()) return <></>;
    return (
    <Container maxWidth="sm" style={{ paddingTop: '2em', paddingBottom: '6em' }}>
        <Grid container direction="column" justifyContent="right" alignItems="right" spacing={2}>
            {alert.show && <Grid item><Alert severity={alert.severity}>{alert.message}</Alert></Grid>}

            <Grid item>
                <Typography variant="h6" style={{ marginBottom: '0.5em' }}>Upcoming Events</Typography>
                <Stack spacing={0.5}>
                    {futureEvents.map(eventCard)}
                </Stack>
                <Typography variant="h6" style={{ marginBottom: '0.5em', marginTop: '1em' }}>Past Events</Typography>
                <Collapse in={pastEventsExpanded} collapsedSize={105} sx={{position: 'relative'}}>
                    <Stack spacing={0.5}>
                        {pastEvents.map(eventCard)}
                    </Stack>
                
                    {!pastEventsExpanded && <Box
                        onClick={() => setPastEventsExpanded(!pastEventsExpanded)}
                        sx={{
                            background: `linear-gradient(to top, ${backgroundColor}, ${chroma(backgroundColor).alpha(0).css()} 50px)`,
                            // background: `linear-gradient(to top, red, rgba(0, 0, 0, 0) 50px)`,
                            cursor: 'pointer',
                            height: '50px',
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            left: 0,
                        }}
                        />}
                </Collapse>
                <IconButton onClick={() => setPastEventsExpanded(!pastEventsExpanded)}>
                    {pastEventsExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                {/* {pastEvents.map(event => (
                    <div key={event._id}><EventLine event={event} /></div> 
                ))} */}
            </Grid>

            {/* <Grid item>
                <Typography variant="h4" style={{ marginBottom: '0.5em' }}>Members</Typography>
                {!users ? (
                    <CircularProgress />
                ) : 
                    users.map(user => (
                        <div key={user._id}>{user.displayName}</div> 
                    ))
                }
            </Grid> */}
            {sessionUser && sessionUser.status == 'established' && <Grid item>
                <Typography variant="h6" style={{ marginBottom: '0.5em' }}>Invite someone</Typography>
                <SendInvite />
            </Grid>}
        </Grid>
    </Container>);
}

export default Dashboard;
