import React, { useContext } from 'react';

import {useLocation, useNavigate, BrowserRouter as Router, Route, Routes, Link as RouterLink } from 'react-router-dom';
import { Link, Container,Typography, Box, Button, Modal, useMediaQuery } from '@mui/material';
import { SessionProvider, SessionContext } from './contexts/SessionContext';
import { UserContext, UsersProvider } from './contexts/UsersContext';
import Layout from './molecules/GlobalLayout';
import { Helmet } from 'react-helmet';

import Landing from './views/Landing';
import SendOtpView from './views/SendOtpView';
import VerifyOtpView from './views/VerifyOtpView';
import SetDisplayName from './views/SetDisplayName';
import KaisPlace from './views/KaisPlace';
import Dashboard from './views/Dashboard';
import RequestInvitation from './views/RequestInvitation';
import Event from './views/Event';
import 'react-add-to-calendar/dist/react-add-to-calendar.css';

import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        secondary: {
            main: '#555555',
        },
    },
});


const ModalMap = {
    debugSession: (<>
        <Typography id="modal-modal-title" variant="h6" component="h2">
            Debug session
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            A voluntary participant shares something they struggle with or wonder about. They get 10 minutes of discussion with the group. It usually starts with questions from other participants to clarify what they have on their mind. It then follows with people sharing similar interogations or situations they have experienced, how they managed it and what they concluded. Some personalized advice may be provided or a plan of action laid out.
        </Typography>
    </>),
    socratesCafe: (<>
        <Typography id="modal-modal-title" variant="h6" component="h2">
            Socrates cafe
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Anyone is free to propose a topic of discussion, usually phrased as an open question, on topics related to life, society, philosophy, or psychology, among others. We then have a group vote and start the discussion. Participants take turns talking, trying their best to keep their point clear and succinct and to avoid interrupting others. The session usually lasts one hour.
        </Typography>
    </>),
    bigTalk: (<>
        <Typography id="modal-modal-title" variant="h6" component="h2">
            Big Talk
        </Typography>
        <ul>
            <li>"What's the most life-changing experience you've ever had?"</li>
            <li>"If you could solve one world problem, what would it be and why?" </li>
            <li>"Where do you see yourself in ten years, and what are you doing to get there?"</li>
        </ul>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You are matched with a random participant and you randomly pick a question like the ones above. You have 3 minutes to answer. Then it is their turn to pick a question and answer, after which you are matched with a new partner.
        </Typography>
    </>),
}

const ContextedApp = () => {
    const { sessionUser } = useContext(SessionContext);
    const navigate = useNavigate();
    const location = useLocation();
    const visibleModal = location.hash.slice(1);
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const handleCloseModal = () => navigate(location.pathname);
    const modalBody = (
        <Box sx={{ 
            position: 'absolute', 
            top: isSmallScreen ? 0 : '50%', 
            left: isSmallScreen ? 0 : '50%', 
            transform: isSmallScreen ? 'none' : 'translate(-50%, -50%)', 
            width: isSmallScreen ? '100%' : 400, 
            height: isSmallScreen ? '100%' : 'auto',
            borderRadius: isSmallScreen ? 0 : 2,
            bgcolor: 'background.paper', 
            boxShadow: 24, 
            p: 4,
            overflowY: 'auto',
            display: 'flex', // Set display to flex
            flexDirection: 'column', // Stack children vertically
            justifyContent: 'center', // Center children vertically
        }}>
            {ModalMap[visibleModal]}
            <Box><Link component={RouterLink} to={location.pathname}><Button variant="contained" sx={{ mt: 2 }} style={{ textTransform: 'none' }}>
                Close
            </Button></Link></Box>
        </Box>
    );

    return (
        <Layout>
            <Helmet>
                <title>Maslow Climbers</title>
            </Helmet>
            <Routes>
                <Route path="/" element={sessionUser ? <Dashboard/> : <Landing/>} />
                <Route path="/dashboard" element={<Dashboard/>} />
                <Route path="/landing" element={<Landing/>} />
                <Route path="/login" element={<SendOtpView/>} />
                <Route path="/verify" element={<VerifyOtpView/>} />
                <Route path="/whats-your-name" element={<SetDisplayName/>} />
                <Route path="/request-invitation" element={<RequestInvitation/>} />
                <Route path="/event/:eventId" element={<Event />} />
                <Route path="/kai-s-place" element={<KaisPlace />} />
            </Routes>

            <Modal
                open={!!visibleModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {modalBody}
            </Modal>

        </Layout>
    );
}
const App = () => (
    <ThemeProvider theme={theme}>
        <SessionProvider>
            <UsersProvider>
                <Router>
                    <ContextedApp />
                </Router>
            </UsersProvider>
        </SessionProvider>
    </ThemeProvider>);

export default App;
