import React, { } from 'react';
import {  Grid,  Typography, Skeleton } from '@mui/material';
import ShortTapTooltip from './generic/ShortTapTooltip';
import { getWeek } from 'date-fns';

const EventLine = ({event}) => {
    //  animation="wave"
    if(!event.startAt) return <Skeleton style={{ maxWidth: 180 }} />
    return (
    <Grid container key={event._id} spacing={1} alignItems="center" justifyContent="flex-start">
        <Grid item>
            <ShortTapTooltip
                title={new Date(event.startAt).toLocaleDateString('en-UK', {
                    weekday: 'long', day: 'numeric',month: 'long', year: 'numeric'
                })+', week #' + getWeek(new Date(event.startAt))}>
                <Typography
                variant="subtitle2"
                style={{  }}>
                    {new Date(event.startAt).toLocaleDateString('en-UK', { weekday: 'short' }) + ', '}
                    {new Date(event.startAt).toLocaleDateString('en-UK', { month: 'short' }).substring(0,3)+' '}
                    {new Date(event.startAt).toLocaleDateString('en-UK', { day: 'numeric' })}
                </Typography>
            </ShortTapTooltip>
        </Grid>
        <Grid item>
            <Typography variant="subtitle2" style={{ color: 'gray' }}>
                {'from '+new Date(event.startAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                {/* {' - '}
                {new Date(event.endAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} */}
            </Typography>
        </Grid>
    </Grid>)
}

export default EventLine;
