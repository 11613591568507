import React, { useContext, useEffect, useState } from 'react';
import { Container, Grid, Link, Alert, Typography, Backdrop, Button, Skeleton } from '@mui/material';
import { SessionContext } from '../contexts/SessionContext';
import { UsersContext } from '../contexts/UsersContext';
import { useParams } from "react-router-dom";
import useAutoRedirect from '../hooks/useAutoRedirect';
import UserTimeRange from '../molecules/events/UserTimeRange';
import EventLine from '../atoms/EventLine';
import Box from '@mui/material/Box';
import axios from 'axios';
import { Collapse, IconButton } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import { canEditRsvp, isFutureEvent } from '../helpers/events';
import 'add-to-calendar-button';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

// const objToRange = obj=> obj && obj?.startAt ? [
//     new Date(obj.startAt).getHours() + new Date(obj.startAt).getMinutes() / 60,
//     new Date(obj.endAt).getHours() + new Date(obj.endAt).getMinutes() / 60
// ] : undefined;

const objToRange = obj => {
    if (!(obj && obj.startAt && obj.endAt)) return undefined;
    const start = new Date(obj.startAt);
    const end = new Date(obj.endAt);
    const startOfDay = new Date(start);
    startOfDay.setHours(0, 0, 0, 0); // Set to start of the day
    const differenceFromStartOfDayInHours = (start - startOfDay) / (1000 * 60 * 60);
    const differenceInHours = (end - start) / (1000 * 60 * 60);
    return [ differenceFromStartOfDayInHours, differenceFromStartOfDayInHours + differenceInHours ];
  };

// day expected to be a date (in string or Date format) within the correct day, and with second and milliseconds at 0
const rangeToObj = (day, range) => {
    if (!range || range.length !== 2) return undefined;

    const startAt = new Date(day);
    startAt.setHours(Math.floor(range[0]));
    startAt.setMinutes((range[0] % 1) * 60);

    const endAt = new Date(day);
    endAt.setHours(Math.floor(range[1]));
    endAt.setMinutes((range[1] % 1) * 60);

    return { startAt, endAt };
};

const AttendanceRow = ({attendance, maxRange, onNewRange})=> {
    const { sessionUser } = useContext(SessionContext);
    const { users } = useContext(UsersContext);
    const user = users && users.find(u=>u._id == attendance.user);
    // const userById = !users ? {} : users.reduce((acc, user) => ({ ...acc, [user._id]: user }), {});
    // if(canEditEventRsvp && attendance?.user == sessionUser._id) return;
    const range = objToRange(attendance);
    const fromSessionUser = attendance?.user == sessionUser._id;
    return (
        <UserTimeRange
            skeleton={!attendance.startAt}
            user={user || {}}
            maxRange={maxRange}
            range={range}
            keyColored={fromSessionUser}
            onNewRange={onNewRange}
        />
    );
}


const Event = () => {
    const { eventId } = useParams();
    const theme = useTheme();
    const backgroundColor = theme.palette.background.default;
    const [alert, setAlert] = useState({ show: false, message: '', severity: '' });
    const { sessionToken, sessionUser } = useContext(SessionContext);
    const [event, setEvent] = useState();
    const [selectedRange, setSelectedRange] = useState();
    const [attendances, setAttendances] = useState();
    const [openModal, setOpenModal] = useState(false);
    
    const maxRange = objToRange(event) || [0,24];
    const canEditEventRsvp = event && canEditRsvp(event);
    const maxCapacity = event?.maxAttendees || 10;
    const currentOcupancy = attendances ? attendances.length : undefined;
    const atMaxCapacity = currentOcupancy !== undefined && currentOcupancy >= maxCapacity;

    const userAttendance = attendances && attendances.find(a=>a.user == sessionUser._id);
    const othersAttendances = attendances && attendances.filter(a=>a.user != sessionUser._id);
    const userAttendanceRange = objToRange(userAttendance);
    const userIsAttending = userAttendanceRange && userAttendanceRange[0] <= userAttendanceRange[1];
    
    let eventForCalendar = event && isFutureEvent(event) && userAttendance && {
        name: "Maslow Climbers",
        description: `More details at https://maslow-climbers.club/event/${event._id}`,
        location: '16 rue Beautrellis, 75004 Paris',
        startDate: new Date(userAttendance.startAt).toLocaleDateString('en-CA'),
        endDate:  new Date(userAttendance.endAt).toLocaleDateString('en-CA'),
        startTime:  new Date(userAttendance.startAt).toLocaleTimeString('en-GB'),
        endTime:  new Date(userAttendance.endAt).toLocaleTimeString('en-GB'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        options: "'Google','Apple','iCal'",
        // options: "'Google','Apple','Microsoft365','iCal','Outlook.com'",

    };


    useEffect(() => {
        const fetchEvent = async () => {
            try {
                // await new Promise(resolve => setTimeout(resolve, 2500));
                const response = await axios.get(`/api/event/${eventId}`, {
                    headers: {
                        'x-access-token': sessionToken
                    }
                });
                const { event, attendances } = response.data;
                const sortedAttendances = attendances.sort((a, b) => {
                    if (a.user === sessionUser._id) return -1;
                    if (b.user === sessionUser._id) return 1;
                    const startDiff = new Date(a.startAt) - new Date(b.startAt);
                    if(startDiff != 0) return startDiff;
                    const endDiff = new Date(a.endAt) - new Date(b.endAt);
                    return endDiff;
                    // return 0;
                  });
                const userAttendance = attendances.find(a=>a.user == sessionUser._id);
                if(userAttendance) setSelectedRange(objToRange(userAttendance));
                setEvent(event);
                setAttendances(sortedAttendances);
            } catch (error) {
                console.error(error);
                if (error.response) {
                    if (error.response.status === 404) {
                        setAlert({ show: true, message: 'Event not found.', severity: 'error' });
                    } else {
                        setAlert({ show: true, message: 'Something went wrong. Try refreshing.', severity: 'error' });
                    }
                } else if (error.request) {
                    setAlert({ show: true, message: 'No response from server. Check your connection and refresh.', severity: 'error' });
                } else {
                    setAlert({ show: true, message: 'Something went wrong. Try refreshing.', severity: 'error' });
                }
            }
        };

        if(sessionToken) fetchEvent();
    }, []);

    const saveAttendance = async (attending=true) => {
        if(!event || !canEditEventRsvp) return;

        // optimisitic
        if(!attending) setAttendances([...othersAttendances]);
        else setAttendances([
            {
                _id: 'new',
                user: sessionUser._id,
                ...rangeToObj(event.startAt, selectedRange),
                createdAt: new Date(),
            },
            ...othersAttendances,
        ]);
        setOpenModal(false);

        try {
            const response = await axios.post(`/api/event/${eventId}/addAttendance`,
                attending ? rangeToObj(event.startAt, selectedRange)
                    : rangeToObj(event.startAt, [(maxRange[0]+maxRange[1])/2, (maxRange[0]+maxRange[1])/2]), {
                headers: {
                    'x-access-token': sessionToken
                }
            });
    
            // Update the attendances with the latest data from the server
            const { attendances } = response.data;
            setAttendances(attendances);
    
        } catch (error) {
            console.error('Error saving attendance:', error);
            // Handle the error appropriately
            // For example, show an alert to the user
            setAlert({ show: true, message: 'Failed to save attendance. Please try again.', severity: 'error' });
        }

    };
    const onDeleteAttendance = () => saveAttendance(false);

    const onNewRange = openModal ? newRange=> {
        const changingStart = newRange[0] !== selectedRange[0];
        // impose minimum 1 hour attendance
        if(newRange[1] - newRange[0] < 1.0) {
            if(changingStart) newRange[0] = newRange[1] - 1.0;
            else newRange[1] = newRange[0] + 1.0;
        }
        // console.log(changingStart, selectedRange, objToRange(attendance));
        setSelectedRange(newRange);
    } : undefined;

    const onOpenAttendanceEditor = () => {
        if(!event || !canEditEventRsvp) return;
        // rounded by 0.5 increments
        const midRange = Math.round(((maxRange[0]+maxRange[1])/2)*2)/2;
        const newRange = selectedRange||[midRange,midRange];
        setSelectedRange(newRange);
        setOpenModal(selectedRange ? 'excludeIntro' : true);
    };

    const toPrettyTime = date=>{
        const h24 = new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        if(h24 == '12:00') return 'noon';
        if(h24 == '24:00' || h24 == '00:00') return 'midnight';
        return h24;
    }

    const eventDescriptions = {
        default: (
          <Typography variant="body1">
            Come any time to catch up and share with other members. A <Link component={RouterLink} to="#socratesCafe">Socrates cafe</Link> will be organized between 15:00 and 16:00, and a <Link component={RouterLink} to="#debugSession">debug session</Link> between 16:30 and 17:30. We might then have dinner at 19:00, either home or out.
          </Typography>
        ),
        '2024-01-20': (
          <Typography variant="body1">
            Come any time to catch up and share with other members. There will be a group conversation about building community, <Link component={RouterLink} to="https://en.wikipedia.org/wiki/Third_place" target="_blank" rel="noopener noreferrer">Third Places</Link>, and the diverse directions that Maslow Climbers could be taking between 15:00 and 16:00, and a <Link component={RouterLink} to="#debugSession">debug session</Link> between 16:30 and 17:30. We might then have dinner at 19:00, either home or out.
          </Typography>
        ),
        '2024-02-17': (
          <Typography variant="body1">
            Come any time to catch up and share with other members. Pierre will lead a group conversation on finding courage amidst fear between 15:00 and 16:00, and we'll continue with a <Link component={RouterLink} to="#debugSession">debug session</Link> between 16:30 and 17:30. We might then have dinner at 19:00, either home or out.
          </Typography>
        ),
        '2024-03-01': (
          <Typography variant="body1">
            Let's be focused on getting work done, together, with regular breaks to follow up on each other's progress. As much as possible, target to arrive and to leave outside of a session to avoid disrupting the focus of others. Also bring noise-cancelling headphones if you have some and want extra isolation during the sessions.
            <ul>
                <li>13:30 - 14:00: We say hi and share our intentions for the day and the coming hour.</li>
                <li>14:00 - 14:50: Session 1, focused on work.</li>
                <li>14:50 - 15:00: break, debriefing and intention for the next hour.</li>
                <li>15:00 - 15:50: Session 2, focused on work.</li>
                <li>15:50 - 16:00: break, debriefing and intention for the next hour.</li>
                <li>16:00 - 16:50: Session 3, focused on work.</li>
                <li>16:50 - 17:00: break, debriefing and intention for the next hour.</li>
                <li>17:00 - 17:50: Session 4, focused on work.</li>
                <li>17:50 - 18:30: global debriefing and intention for the coming week.</li>
            </ul>
          </Typography>
        ),
        '2024-03-06': (
          <Typography variant="body1">
            Let's bath in a crowd of strangers and sing!<br/>
            <Link component={RouterLink} to="https://www.instagram.com/enviedechanter_choirshow" target="_blank" rel="noopener noreferrer">Instagram page of the event.</Link>
        </Typography>
        ),
        '2024-03-08': (
          <Typography variant="body1">
            Let's be focused on getting work done, together, with regular breaks to follow up on each other's progress. As much as possible, target to arrive and to leave outside of a session to avoid disrupting the focus of others. Also bring noise-cancelling headphones if you have some and want extra isolation during the sessions.
            <ul>
                <li>13:30 - 14:00: We say hi and share our intentions for the day and the coming hour.</li>
                <li>14:00 - 14:50: Session 1, focused on work.</li>
                <li>14:50 - 15:00: break, debriefing and intention for the next hour.</li>
                <li>15:00 - 15:50: Session 2, focused on work.</li>
                <li>15:50 - 16:00: break, debriefing and intention for the next hour.</li>
                <li>16:00 - 16:50: Session 3, focused on work.</li>
                <li>16:50 - 17:00: break, debriefing and intention for the next hour.</li>
                <li>17:00 - 17:50: Session 4, focused on work.</li>
                <li>17:50 - 18:30: global debriefing and intention for the coming week.</li>
            </ul>
          </Typography>
        ),
        '2024-04-27': (
          <Typography variant="body1">
            A <Link component={RouterLink} to="#debugSession">debug session</Link> will be organized between 14:45 and 16:00, and a <Link component={RouterLink} to="#socratesCafe">Socrates cafe</Link> between 16:15 and 17:30. You are welcome to hang around for infirmal conversation after that.
          </Typography>
        ),
        '2024-05-25': (
          <Typography variant="body1">
            A <Link component={RouterLink} to="#debugSession">debug session</Link> will be organized between 14:45 and 16:00, and a <Link component={RouterLink} to="#socratesCafe">Socrates cafe</Link> between 16:15 and 17:30. You are welcome to hang around for infirmal conversation after that.
          </Typography>
        ),
      };
      
    const venues = {
        default: (
            <Link component={RouterLink} to="/kai-s-place">Kai's place</Link>
        ),
        '2024-03-06': (
            <Link component={RouterLink} to="https://maps.app.goo.gl/gsnVkMv1hBsRtuAg9" target="_blank" rel="noopener noreferrer">Le Grand Breguet</Link>
        ),

    }
      // Then, within the Event component, you can select the description based on the event's start date:
      const eventDescription = (event?.startAt && eventDescriptions[new Date(event?.startAt).toISOString().split('T')[0]]) || eventDescriptions.default;
      const venue = (event?.startAt && venues[new Date(event?.startAt).toISOString().split('T')[0]]) || venues.default;

    
    if(useAutoRedirect()) return <></>;
    return (
        <Container maxWidth="sm" style={{ paddingTop: '2em', paddingBottom: '6em' }}>

            <Backdrop
                open={!!openModal}
                sx={{ backgroundColor: chroma(backgroundColor).alpha(0.6).css(), zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            </Backdrop>
            <Backdrop
                open={!!openModal}
                sx={{ backgroundColor: chroma(backgroundColor).alpha(0.4).css(), zIndex: (theme) => theme.zIndex.drawer + 3 }}>
            </Backdrop>

            <Grid container direction="column" spacing={2}>

                {alert.show && <Grid item><Alert severity={alert.severity}>{alert.message}</Alert></Grid>}

                <Grid item>
                    <Typography variant="h4" align="left">{event?.title || <Skeleton width="7em" />}</Typography>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                        <li>Date: {event ? new Date(event.startAt).toLocaleDateString('en-UK', {
                                weekday: 'long', day: 'numeric',month: 'long', year: 'numeric'
                            }) : <Skeleton sx={{display:'inline-block', width:'12.5em'}}></Skeleton>}
                        </li>
                        <li>Time: {event
                            ? `${_.capitalize(toPrettyTime(event.startAt))} to ${toPrettyTime(event.endAt)}`
                            : <Skeleton sx={{display:'inline-block', width:'7em'}}></Skeleton>
                        }</li>
                        <li>{venue}</li>
                    </ul>
                    {eventDescription}
                </Grid>

                <Grid item><Typography variant="h5">Attendees{canEditEventRsvp && <span style={{fontSize:'1rem', marginLeft:'1.5rem'}}>
                    {maxCapacity - currentOcupancy} seats left / {maxCapacity}</span>}</Typography></Grid>

                {!!event?.cancelledAt && <Grid item><Alert severity='warning'>Event cancelled.</Alert></Grid>}

                {event && isFutureEvent(event) && !event.cancelledAt && !canEditEventRsvp && (
                    <Grid item><Typography variant="body1">Registrations will be open 2 weeks before the event.</Typography></Grid>
                )}

                {openModal && openModal != 'excludeIntro' && (
                    <Grid item
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 4 }}><Typography variant="body2">Please select the time you expect to be present. This helps others know who will be present and manage the maximum capacity of the venue.</Typography></Grid>
                )}

                {(!!userAttendance || openModal) && canEditEventRsvp && (
                    <Grid item sx={{ zIndex: (theme) => theme.zIndex.drawer + 4 }}>
                        <AttendanceRow attendance={!openModal ? userAttendance : {
                            ...rangeToObj(event.startAt, selectedRange),
                            user: sessionUser._id,
                        }} maxRange={maxRange} onNewRange={onNewRange} />
                    </Grid>
                )}

                {openModal && (
                    <Grid item sx={{ zIndex: (theme) => theme.zIndex.drawer + 4 }}>
                        <Button
                            variant="contained"
                            disabled={selectedRange[0] == selectedRange[1]}
                            style={{ textTransform: 'none' }}
                            onClick={saveAttendance}>Done!</Button>
                        <Button
                            style={{ textTransform: 'none' }}
                            onClick={()=> setOpenModal(false)}>Cancel</Button>
                    </Grid>
                )}

                {canEditEventRsvp && !userIsAttending && !openModal && (<Grid item>
                    {!atMaxCapacity
                        ? <Button
                            variant="contained" 
                            style={{ textTransform: 'none' }}
                            onClick={onOpenAttendanceEditor}>I'll be there!</Button>
                        : <Typography variant="body1">Venue at full capacity.</Typography>
                    }
                </Grid>)}

                {canEditEventRsvp && userIsAttending && !openModal && (
                        <Grid item container direction="row" spacing={1} alignItems="center">
                            <Grid item><Button
                                style={{ textTransform: 'none', marginBottom: '8px' }}
                                onClick={onDeleteAttendance}>I can't make it after all</Button></Grid>
                            <Grid item><Button
                                style={{ textTransform: 'none', marginBottom: '8px' }}
                                onClick={onOpenAttendanceEditor}>Update my time range</Button></Grid>
                            {eventForCalendar && <Grid item sx={{marginTop:'-7px'}}><add-to-calendar-button
                                {...eventForCalendar}
                                lightMode="dark"
                                hideBranding="true"
                                size="2|2|2"
                                hideCheckmark="true"
                                buttonStyle="text"
                                trigger="hover"
                            /></Grid>}
                    </Grid>
                )}

                <Grid item container direction="column" spacing={1}>

                    {(canEditEventRsvp ? othersAttendances : attendances || Array.from({length: 7}, (_, i) => ({_id: i})) )
                        .map(attendance => (
                        <Grid item key={attendance._id} sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
                            <AttendanceRow maxRange={maxRange} attendance={attendance}/>
                        </Grid>
                    ))}

                </Grid>

            </Grid>

        </Container>);
}

export default Event;
