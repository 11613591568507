
export function isFutureEvent(event) {
    const now = new Date();
    const eventEnd = new Date(event.endAt);
    return eventEnd > now;
}

export function canEditRsvp(event) {
    if(!event || !!event.cancelledAt) return false;
    const now = new Date();
    const twoWeeksFromNow = new Date();
    twoWeeksFromNow.setDate(now.getDate() + 7*12); // passing to 5 weeks // passing to 12 weeks, cancelling the feature basically

    const eventStart = !event ? null : new Date(event.startAt);
    const eventEnd = !event ? null : new Date(event.endAt);

    const isEventEndInFuture = eventEnd ? eventEnd > now : false;
    const isEventStartBeforeTwoWeeks = eventStart ? eventStart < twoWeeksFromNow || event._id === '65492db83bfc26122bc24bc6' : false;

    return isEventEndInFuture && isEventStartBeforeTwoWeeks;
}